<template>
	<div>
		<el-row class="distribute-right-box">
			<el-col :span="10" class="left-list default-height">
				<!-- 一级菜单 -->
				<div v-if="showloading" class="loading-box">
					<img src="../../assets/img/loading.png">
					<span>加载中</span>
				</div>
				<el-collapse v-if="!showloading" v-model="activeName" accordion class="list-menu">
					<div class="list-menu-item" v-for="(item, index) in menuList" v-bind:key="index">
						<el-collapse-item :name="index + 'f'">
							<template slot="title">
								<div class="custon-menu-title">
									<i :class="item.icon"></i>
									<span>{{ item.name }}</span>
								</div>
							</template>
							<template v-if="item.children && item.children[0].type == 1">
								<!-- 二级菜单 -->
								<el-collapse v-model="subactiveName" accordion class="list-sub-menu">
									<div class="list-sub-menu-item" v-for="(subitem, subindex) in item.children" v-bind:key="subindex">
										<el-collapse-item :name="subindex + 's'">
											<template slot="title">
												<div class="custon-menu-title">
													<i :class="item.icon"></i>
													<span>{{ subitem.name }}</span>
												</div>
											</template>
											<div v-if="subitem.children">
												<!-- <按钮 -->
												<el-button class="right-btn" v-for="(thirditem, thirdindex) in subitem.children" :type="thirditem.icon" size="mini" plain v-bind:key="thirdindex">
													{{ thirditem.name }}
													<el-checkbox v-model="thirditem.checked" @change="thirdChange($event, thirditem)"></el-checkbox>
												</el-button>
											</div>
										</el-collapse-item>
										<el-checkbox v-model="subitem.checked" size="medium" @change="secondChange($event, subitem)" class="first-check"></el-checkbox>
									</div>
								</el-collapse>
							</template>
							<div v-else-if="item.children && item.children[0].type == 2">
								<!-- <按钮 -->
								<el-button class="right-btn" v-for="(thirditem, thirdindex) in item.children" :type="thirditem.icon" size="mini" plain v-bind:key="thirdindex">
									{{ thirditem.name }}
									<el-checkbox v-model="thirditem.checked" @change="thirdChange($event, thirditem)"></el-checkbox>
								</el-button>
							</div>
						</el-collapse-item>
						<el-checkbox v-model="item.checked" @change="firstChange($event, item)" class="first-check"></el-checkbox>
					</div>
				</el-collapse>
			</el-col>
			<el-col :span="4" class="center-route default-height">
				<el-button class="remove-right" size="mini" type="warning" @click="remove">
					<i class="el-icon-d-arrow-left"></i>移除
				</el-button>
				<el-button class="add-right" size="mini" type="info" @click="add">
					添加<i class="el-icon-d-arrow-right"></i>
				</el-button>
			</el-col>
			<el-col :span="10" class="right-list default-height">
				<!-- 一级菜单 -->
				<div v-if="showloadingR" class="loading-box">
					<img src="../../assets/img/loading.png">
					<span>加载中</span>
				</div>
				<el-collapse v-if="!showloadingR" v-model="activeNameRight" accordion class="list-menu">
					<div class="list-menu-item" v-for="(item, index) in roleRightList" v-bind:key="index">
						<el-collapse-item :name="index + 'f'">
							<template slot="title">
								<div class="custon-menu-title">
									<i :class="item.icon"></i>
									<span>{{ item.name }}</span>
								</div>
							</template>
							<template v-if="item.children && item.children[0].type == 1">
								<!-- 二级菜单 -->
								<el-collapse v-model="subactiveNameRight" accordion class="list-sub-menu">
									<div class="list-sub-menu-item" v-for="(subitem, subindex) in item.children" v-bind:key="subindex">
										<el-collapse-item :name="subindex + 's'">
											<template slot="title">
												<div class="custon-menu-title">
													<i :class="item.icon"></i>
													<span>{{ subitem.name }}</span>
												</div>
											</template>
											<div v-if="subitem.children">
												<!-- <按钮 -->
												<el-button class="right-btn" v-for="(thirditem, thirdindex) in subitem.children" :type="thirditem.icon" size="mini" plain v-bind:key="thirdindex">
													{{ thirditem.name }}
													<el-checkbox v-model="thirditem.checked" @change="thirdChange($event, thirditem,1)"></el-checkbox>
												</el-button>
											</div>
										</el-collapse-item>
										<el-checkbox v-model="subitem.checked" size="medium" @change="secondChange($event, subitem,1)" class="first-check"></el-checkbox>
									</div>
								</el-collapse>
							</template>
							<div v-else-if="item.children && item.children[0].type == 2">
								<!-- <按钮 -->
								<el-button class="right-btn" v-for="(thirditem, thirdindex) in item.children" :type="thirditem.icon" size="mini" plain v-bind:key="thirdindex">
									{{ thirditem.name }}
									<el-checkbox v-model="thirditem.checked" @change="thirdChange($event, thirditem,1)"></el-checkbox>
								</el-button>
							</div>
						</el-collapse-item>
						<el-checkbox v-model="item.checked" @change="firstChange($event, item,1)" class="first-check"></el-checkbox>
					</div>
				</el-collapse>
			</el-col>
		</el-row>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<!-- <el-button size="small" type="primary" @click="handleChangeConfirm">确 定</el-button> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'distributeRight',
	props: {
		selectdata:{
			type:Object,
			default:()=>{}
		}
	},
	data() {
		return {
			checked: false,
			showloading:false,
			showloadingR:false,
			activeName: '1',
			subactiveName:"1",
			activeNameRight:"1",
			subactiveNameRight:"1",
			menuList: [],
			roleRightList:[],
			currentRoleRight:[]
		};
	},
	watch:{
		selectdata(val){
			this.getRoleRightList();
		}
	},
	mounted() {
		this.getMenuList();
		this.getRoleRightList();
	},
	methods: {
		firstChange(e, item,type) {
			//type为1表示右边列表
			if(type==1){
				this.roleRightList.map((v,index) => {
					if(v.id == item.id){
						this.$set(this.roleRightList[index],"checked",e)
						if(v.children){
							v.children.map((sub,sindex)=>{
								this.$set(this.roleRightList[index].children[sindex],"checked",e)
								if(sub.children){
									sub.children.map((third,tindex)=>{
										this.$set(this.roleRightList[index].children[sindex].children[tindex],"checked",e)
									})
								}
							})
						}
					}
				})
				this.$forceUpdate()
				return;
			}
			this.menuList.map((v,index) => {
				if(v.id == item.id){
					this.$set(this.menuList[index],"checked",e)
					if(v.children){
						v.children.map((sub,sindex)=>{
							this.$set(this.menuList[index].children[sindex],"checked",e)
							if(sub.children){
								sub.children.map((third,tindex)=>{
									this.$set(this.menuList[index].children[sindex].children[tindex],"checked",e)
								})
							}
						})
					}
				}
			})
			this.$forceUpdate()
		},
		secondChange(e, item,type) {
			if(type==1){
				let currentSubchildrenLength = 0 
				this.roleRightList.map((v,index) => {
					if(v.id == item.pid){
						if(v.children){
							v.children.map((sub,sindex)=>{
								if(sub.checked)
									currentSubchildrenLength ++ 
								if(sub.id == item.id){
									this.$set(this.roleRightList[index].children[sindex],"checked",e)
									if(sub.children){
										sub.children.map((third,tindex)=>{
											this.$set(this.roleRightList[index].children[sindex].children[tindex],"checked",e)
										})
									}
								}
							})
						}
					}
				})
				this.$forceUpdate()
				this.roleRightList.map((v,index) => {
					if(v.id == item.pid){
						this.$set(this.roleRightList[index],"checked",currentSubchildrenLength>0 ? true:false)
					}
				})
				return;
			}
			let subchildrenLength = 0 
			this.menuList.map((v,index) => {
				if(v.id == item.pid){
					if(v.children){
						v.children.map((sub,sindex)=>{
							if(sub.checked)
								subchildrenLength ++ 
							if(sub.id == item.id){
								this.$set(this.menuList[index].children[sindex],"checked",e)
								if(sub.children){
									sub.children.map((third,tindex)=>{
										this.$set(this.menuList[index].children[sindex].children[tindex],"checked",e)
									})
								}
							}
						})
					}
				}
			})
			this.$forceUpdate()
			this.menuList.map((v,index) => {
				if(v.id == item.pid){
					this.$set(this.menuList[index],"checked",subchildrenLength>0 ? true:false)
				}
			})
		},
		thirdChange(e, item,type) {
			if(type==1){
				let currentSubchildrenLength = 0 
				let currentLengthR = 0
				this.roleRightList.map((v,index) => {
					if(v.children){
						v.children.map((sub,sindex)=>{
							if(sub.children){
								sub.children.map((third)=>{
									if(third.checked)
										currentSubchildrenLength ++ 
								})
							}
							if(sub.children && sub.id == item.pid){
								sub.children.map((third,tindex)=>{
									if(third.checked){
										currentLengthR ++
									}
									if(third.id == item.id)
										this.$set(this.roleRightList[index].children[sindex].children[tindex],"checked",e)
								})
							}
						})
					}
				})
				this.$forceUpdate()
				this.roleRightList.map((v,index) => {
					if(v.children){
						v.children.map((sub,sindex)=>{
							if(sub.children && sub.id == item.pid){
								this.$set(this.roleRightList[index],"checked",currentSubchildrenLength>0 ? true:false)
								this.$set(this.roleRightList[index].children[sindex],"checked",currentLengthR>0 ? true:false)
							}
						})
					}
				})
				return;
			}
			let thirdchildrenLength = 0 
			let currentLength = 0
			this.menuList.map((v,index) => {
				if(v.children){
					v.children.map((sub,sindex)=>{
						if(sub.children){
							sub.children.map((third)=>{
								if(third.checked)
									thirdchildrenLength ++ 
							})
						}
						if(sub.children && sub.id == item.pid){
							sub.children.map((third,tindex)=>{
								if(third.checked){
									currentLength ++
								}
								if(third.id == item.id)
									this.$set(this.menuList[index].children[sindex].children[tindex],"checked",e)
							})
						}
					})
				}
			})
			this.$forceUpdate()
			this.menuList.map((v,index) => {
				if(v.children){
					v.children.map((sub,sindex)=>{
						if(sub.children && sub.id == item.pid){
							this.$set(this.menuList[index],"checked",thirdchildrenLength>0 ? true:false)
							this.$set(this.menuList[index].children[sindex],"checked",currentLength>0 ? true:false)
						}
					})
				}
			})
		},
		add() {
			let addRight = []
			this.menuList.map((v) => {
				if(v.checked){
					addRight.push(v.id)
				}
				if(v.children){
					v.children.map(sub=>{
						if(sub.checked){
							addRight.push(sub.id)
						}
						if(sub.children){
							sub.children.map(third=>{
								if(third.checked){
									addRight.push(third.id)
								}
							})
						}
					})
				}
			})
			addRight = addRight.concat(this.currentRoleRight)
			let result = [...new Set(addRight)]
			this.distributeRight(result)
		},
		remove(){
			let removeRight = []
			let subLength = 0,thirdLength = 0
			console.log(this.roleRightList)
			this.roleRightList.map((v) => {
				if(v.checked && v.children){
					v.children.map(sub=>{
						if(!sub.checked){
							subLength ++
						}else{
							if(sub.checked && sub.children){
								sub.children.map(third=>{
									if(third.checked){
										thirdLength ++ 
										removeRight.push(third.id)
									}
								})
								if(thirdLength == sub.children.length){
									removeRight.push(sub.id)
								}
							}else if(sub.checked){
								removeRight.push(sub.id)
							}
						}
					})
					if(subLength==0){
						removeRight.push(v.id)
					}
				}else if(v.checked){
					removeRight.push(v.id)
				}
			})
			// console.log(removeRight)
			// console.log(this.currentRoleRight)
			this.distributeRight(this.array_diff(this.currentRoleRight,removeRight))
		},
		handleChangeClose() {
			this.$emit('schange', false);
		},
		handleChangeConfirm() {
			this.$emit('schange', false);
		},
		async getRoleRightList() {
			this.showloadingR = true
			this.currentRoleRight=[]
			const res = await this.$axios('GET', `/sys/sysRole/findPermissionsByRoleId?id=${this.selectdata.id}`, {}, true);
			this.roleRightList = res;
			this.roleRightList.map(first => {
				first.checked = false;
				this.currentRoleRight.push(first.id)
				if (first.children) {
					first.children.map(sub => {
						sub.checked = false;
						this.currentRoleRight.push(sub.id)
						if (sub.children) {
							sub.children.map(third => {
								third.checked = false;
								this.currentRoleRight.push(third.id)
							});
						}
					});
				}
			});
			this.showloadingR = false
		},
		async getMenuList() {
			this.showloading = true
			const res = await this.$axios('GET', '/sys/sysPermission/list', {}, true);
			this.menuList =res;
			this.menuList.map(first => {
				first.checked = false;
				if (first.children) {
					first.children.map(sub => {
						sub.checked = false;
						if (sub.children) {
							sub.children.map(third => {
								third.checked = false;
							});
						}
					});
				}
			});
			this.showloading = false
		},
		async distributeRight(ids){
			let params ={
				id:this.selectdata.id,
				permissionIds:ids
			}
			const res = await this.$axios('POST', '/sys/sysRole/permissions', params, true);
			this.$message.success("权限分配成功！")
			this.$vue.$emit("refreshMenuList")
			// $vue.$emit("refreshMenuList")
			this.getRoleRightList()
		},
		array_diff(a, b) {
		    for(var i=0;i<b.length;i++)
		    {
		      for(var j=0;j<a.length;j++)
		      {
		        if(a[j]==b[i]){
		          a.splice(j,1);
		          j=j-1;
		        }
		      }
		    } 
		  return a;
		}
	}
};
</script>

<style>
.distribute-right-box {
	border: 1px solid #ebeef5;
	padding: 16px 0;
	box-sizing: border-box;
}
.default-height {
	height: 420px;
	padding: 14px;
	overflow-y: auto;
	scrollbar-width: none; /* firefox */
	-ms-overflow-style: none; /* IE 10+ */
}
.default-height::-webkit-scrollbar{
	display: none; /* Chrome Safari */
}
.loading-box{
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.loading-box img{
	width: 34px;
	height: 34px;
	animation: myfirst 1s linear infinite;
}
.loading-box span{
	font-size: 12px;
	color: #e2e2e2;
}
	.left-list {
		border-right: 1px solid #ebeef5;
	}
	.right-list {
		border-left: 1px solid #ebeef5;
	}
	.el-collapse {
		border: 0 !important;
	}

	.list-menu .right-btn{
		margin-left: 8px;
		margin-bottom: 8px;
	}
	.list-menu .list-menu-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.list-menu .list-menu-item .first-check {
		margin-top: 11px;
	}
	.list-menu .list-menu-item .custon-menu-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 240px;
	}
	.list-menu .list-menu-item .custon-menu-title i{
		margin-right: 10px;
	}
	.list-sub-menu-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	
	.list-sub-menu-item .first-check {
		margin-top: 11px;
		margin-left: 24px;
	}
	.list-sub-menu-item .custon-menu-title{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 220px;
	}
	.list-sub-menu-item .custon-menu-title i{
		margin-right: 10px;
	}
	
	.el-collapse-item .el-collapse-item__header {
		border: 0 !important;
		justify-content: space-between;
	}
	/* .el-collapse-item__arrow.el-icon-arrow-right {
		// margin-left: 100px;
	}
	.el-collapse-item__wrap {
		// border: 0 !important;
	} */
	.custon-menu-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.custon-menu-title .el-checkbox {
		margin-right: 60px;
	}
	.list-sub-menu {
		padding-left: 20px;
	}
	
	
	
	
	.center-route{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.center-route button{
		margin: 0;
		position: relative;
		border-radius: 0;
	}
	.center-route .remove-right{
		border-radius:0 2px 2px 0;
	}
	.center-route .remove-right::before{
		position: absolute;
		box-sizing: border-box;
		z-index: 1;
		top: -5px;
		left: -18px;
		bottom: 0;
		content: "";
		width: 0;
		height: 0;
		border-top: 18px solid transparent;
		border-right: 18px solid #E6A23C;
		border-bottom: 18px solid transparent;
	}
	.center-route .remove-right:hover:before{
		border-right-color:#EBB563;
	}
	.center-route .add-right{
		margin-top: 24px;
		border-radius: 2px 0 0 2px;
	}
	.center-route .add-right::after{
		position: absolute;
		box-sizing: border-box;
		z-index: 9;
		top: -5px;
		right: -18px;
		bottom: 0;
		content: "";
		width: 0;
		height: 0;
		border-top: 18px solid transparent;
		border-left: 18px solid #909399;
		border-bottom: 18px solid transparent;
	}
	.center-route .add-right:hover:after{
		border-left-color:#A6A9AD;
	}
	
	.btnArea{
		text-align: right;
		padding-top: 20px;
	}
	
@keyframes myfirst
{
    from {
			transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-webkit-transform: rotate(0deg); 
	}
    to {
		transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg); 
	}
}
 
@-webkit-keyframes myfirst /* Safari 与 Chrome */
{
   from {
   		transform: rotate(0deg);
   		-ms-transform: rotate(0deg);
   		-webkit-transform: rotate(0deg); 
   }
   to {
   	transform: rotate(360deg);
   	-ms-transform: rotate(360deg);
   	-webkit-transform: rotate(360deg); 
   }
}
</style>
